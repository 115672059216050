.button {
  &.base {
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
    line-height: 24px;
    font-weight: 600;
    font-size: 16px;
    padding: 10px 25px;
    transition: all 0.2s, color 0.2s ease-in-out;
    cursor: pointer;
    white-space: nowrap;
    width: fit-content;
  }

  &.fluid {
    width: 100%;
    justify-content: center;
  }

  &.button-dark-mode {
    &.primary:hover {
      background-color: #fff;
      color: var(--color-grey1);
    }
  }

  &.primary {
    color: var(--color-white);
    background: var(--color-grey1);
  }

  &.primary:hover {
    background-color: var(--color-primary);
  }

  &.secondary {
    color: var(--color-dark);
  }

  &.tertiary {
    color: var(--color-white);
    background: var(--color-primary);
  }

  &.tertiary:hover {
    background-color: var(--color-dark);
  }

  &.minimal {
    padding: 0;
    color: var(--color-primary);
    background-color: inherit;
  }

  &.minimal-small {
    padding: 0;
    font-size: 14px;
    color: var(--color-grey1);
    background-color: inherit;
  }

  &.minimal-secondary {
    padding: 0;
    color: var(--color-dark);
    background-color: inherit;
  }

  &.cta {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    padding: 10px 60px;
    background-color: var(--color-cta);
    color: var(--color-white);
  }

  &.cta:hover {
    background-color: var(--color-primary);
  }

  &.outline {
    color: var(--color-grey1);
    outline: 3px solid var(--color-grey1);
    outline-offset: -3px;
    background-color: transparent;
  }

  &.outline:hover {
    background-color: var(--color-grey1);
    color: var(--color-white);

    img {
      filter: invert(1);
    }
  }

  &.outline-small {
    font-size: 14px;
    padding: 2px 6px;
    color: var(--color-grey1);
    outline: 2px solid var(--color-grey1);
    outline-offset: -2px;
    background-color: var(--color-white);
  }

  &.outline-small:hover {
    background-color: var(--color-grey1);
    color: var(--color-white);

    img {
      filter: invert(1);
    }
  }

  &.subtle-small {
    font-size: 12px;
    padding: 0px 10px;
    color: #33475B;
    outline: 1px solid #CBD6E2;
    outline-offset: -1px;
    background-color: #FBFDFF;
  }

  &.subtle-small:hover {
    background-color: var(--color-grey1);
    outline: 1px solid var(--color-grey1);
    color: var(--color-white);
  }

  &.cta-subtle {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    padding: 10px 0;
    background-color: transparent;
    color: var(--color-cta);
  }

  &.cta-subtle:hover {
    color: var(--color-grey1);
  }

  &.in-message {
    // font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    padding: 10px 0;
    background-color: transparent;
    color: var(--color-cta);
  }

  &.in-message:hover {
    color: var(--color-grey1);
  }

  &.disabled {
    cursor: not-allowed;
    color: var(--color-grey1);
    background-color: var(--color-grey3);
    outline: none;

    &:hover {
      background-color: var(--color-grey3);
      color: var(--color-grey1);
      outline: none;
    }
  }

  &.has-icon {
    display: inline-flex;
    align-items: center;
    gap: var(--padding-extra-small);
  }
}